import React, { createContext, useContext, useState } from "react";

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  // Global States
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState({});
  // User States
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPracticeAdmin, setIsPracticeAdmin] = useState(false);
  const [isProvider, setIsProvider] = useState(false);
  const [shouldLoadUser, setShouldLoadUser] = useState(true);
  // Provider States
  const [providerId, setProviderId] = useState(null);
  const [isPracticeProvider, setIsPracticeProvider] = useState(false);
  const [providers, setProviders] = useState([]);
  const [allPTProviders, setAllPTProviders] = useState([]);
  const [provider, setProvider] = useState(null);
  // Practice States
  const [practiceId, setPracticeId] = useState(null);
  // Appointments, Calendar, Events, Schedules States
  const [appointments, setAppointments] = useState(null);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [events, setEvents] = useState(null);
  const [unavailableEvents, setUnavailableEvents] = useState(null);
  const [schedules, setSchedules] = useState({});
  const [openingHours, setOpeningHours] = useState([]);
  const [rangeDate, setRangeDate] = useState({});
  const [isCalendarConnected, setIsCalendarConnected] = useState(false);
  const [isCalendarDisconnecting, setIsCalendarDisconnecting] = useState(false);
  const [isAcceptingRequests, setIsAcceptingRequests] = useState(true);
  // Patient States
  const [selectedPatient, setSelectedPatient] = useState(null);
  // Dashboard
  const [layout, setLayout] = useState("CALENDAR");
  const [godModalOpen, setGodModalOpen] = useState(false);
  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState(false);
  // Counters State
  const [appointmentsCounters, setAppointmentsCounters] = useState(null);


  const value = {
    isLoading,
    setIsLoading,
    isFirstLoading,
    setIsFirstLoading,
    meta,
    setMeta,
    isAdmin,
    setIsAdmin,
    isPracticeAdmin,
    setIsPracticeAdmin,
    isProvider,
    setIsProvider,
    providerId,
    setProviderId,
    isPracticeProvider,
    setIsPracticeProvider,
    providers,
    setProviders,
    allPTProviders,
    setAllPTProviders,
    provider,
    setProvider,
    practiceId,
    setPracticeId,
    appointments,
    setAppointments,
    selectedAppointmentId,
    setSelectedAppointmentId,
    events,
    setEvents,
    unavailableEvents,
    setUnavailableEvents,
    schedules,
    setSchedules,
    openingHours,
    setOpeningHours,
    rangeDate,
    setRangeDate,
    isCalendarConnected,
    setIsCalendarConnected,
    isCalendarDisconnecting,
    setIsCalendarDisconnecting,
    isAcceptingRequests,
    setIsAcceptingRequests,
    selectedPatient,
    setSelectedPatient,
    layout,
    setLayout,
    godModalOpen,
    setGodModalOpen,
    isRightDrawerOpen,
    setIsRightDrawerOpen,
    appointmentsCounters,
    setAppointmentsCounters,
    shouldLoadUser,
    setShouldLoadUser
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardState = () => useContext(DashboardContext);
